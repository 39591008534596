@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100vh;
  background-color: #f9fafb;
  margin: 0 auto;
}

#__next {
  min-height: 100vh;
}
